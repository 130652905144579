body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100vh;
  width: 100vw
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #24292e;
}
